import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import * as Req from "../services/request";
import { init } from "../services/method";

export default class Question extends React.Component {
  constructor(props) {
    super(props);
    const obj = { question: "" };

    this.state = obj;
  }

  // submit rating
  submit = async () => {
    const { question } = this.state;
    const { params, listen, mergeState } = this.props;
    let { sourceType, channelId, channel, channelName } = params;

    if (!question.trim()) {
      this.setState({ question: "" });
      return;
    }

    mergeState({ questionModal: false, loading: true });

    /*
     * 1. new channel init again
     * 2. exist channel just send msg
     */
    try {
      await Req.chat({ contact: channelName, type: sourceType, channelId });
    } catch (err) {
      alert(err);
    }

    /* channel exist, send msg */
    if (!channel) {
      const obj = await init(params);
      mergeState(obj);
      channel = obj.channel;
      listen(channel, obj.client);
    }

    channel.sendMessage(question);

    // input back to the empty status
    this.setState({
      question: "",
    });

    mergeState({ loading: false });
  };

  // textarea input method
  input = (e) => {
    this.setState({ question: e.target.value });
  };

  render() {
    const { question } = this.state;
    const { modal } = this.props;

    return (
      <div>
        <Modal isOpen={modal} centered={true}>
          <ModalHeader>请先输入咨询问题（Please enter question）</ModalHeader>
          <ModalBody>
            <Input
              value={question}
              onChange={this.input}
              type="textarea"
              placeholder="chat仅提供订单相关的帮助，其他问题可通过App反馈联系我们。(chat only provides order-related help,Other questions please contact us with app. )"
              rows={5}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submit}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
