import axios from "axios";
import _ from "lodash";

const domain = "https://vanguard.ricepo.com/v1";
//const domain = "http://localhost:3000/v1";
//const domain = 'http://192.168.50.213:3000/v1'

// http method
const http = {
  request(obj) {
    return new Promise((resolve, reject) => {
      // Use auto token in headers
      obj.headers["X-Ricepo-Client"] = "Chat/1.0.0";
      axios(obj)
        .then((res) => {
          if (_.get(res, "data.error") || res.statusCode >= 300) {
            // Transform to angular style error
            res.statusText = res.errMsg;
            res.status = res.statusCode;
            reject(res);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  get(url, headers = {}) {
    return this.request({ method: "GET", url, headers });
  },
  post(url, data = {}, headers = {}) {
    return this.request({ method: "POST", url, data, headers });
  },
  put(url, data = {}, headers = {}) {
    return this.request({ method: "PUT", url, data, headers });
  },
  delete(url, headers = {}) {
    return this.request({ method: "DELETE", url, headers });
  },
};

// create chat task in flex
const chat = (data) => {
  return http.post(`${domain}/flex/chat/create`, data);
};

// flex survey result
const survey = (data) => {
  return http.post(`${domain}/flex/task/survey`, data);
};

export { chat, survey };
