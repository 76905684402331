import React from "react";
import moment from "moment";

export default class Message extends React.Component {
  componentDidUpdate() {
    // use scrollIntoView to adjust layout to reach page bottom
    // but if it coontains nothing, this method will occur error that can't see the input area
    // so use scrollHeight and clientHeight to confirm whether to use adjust or not
    const { scrollHeight, clientHeight } = document.getElementById("messages");
    if (scrollHeight > clientHeight) {
      this.scrollToBottom();
    }
  }

  // method to adjust the scrollWindow to reach the bottom
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "auto" });
  };

  render() {
    const { list, sourceId, mode, sourceType } = this.props;

    return (
      <div className="messages" id="messages">
        {list.map((msg, index) => {
          // param initial
          let [type, name = msg.author.split("_")[0], id] = msg.author.split(
            ":"
          );

          // hide comment
          if (name === "AGENT COMMENTS (Private)") {
            return null;
          }

          /* flex compatible */
          if (!id) {
            if (/\+[0-9]*$/.test(msg.author)) {
              type = "customer";
              name = msg.author;
            } else {
              type = "support";
            }
          }

          const time = moment(msg.dateCreated).format("MM-DD HH:mm");
          let avatar = "";
          let status = "";

          // according to user type, use different avatar
          switch (type) {
            case "support":
              avatar = `ricepo_${mode}.png`;
              break;
            case "customer":
              avatar = `user_${mode}.png`;
              break;
            case "driver":
              avatar = "driver.jpg";
              break;
            default:
              avatar = "user_light.png";
          }

          // userself is in relies part the others is in sent part
          if (sourceId === id || /\+[0-9]*$/.test(msg.author)) {
            status = "replies";
          } else {
            status = "sent";
          }

          // automatic msg
          if (msg.body === "ricepo-automatic-queue") {
            type = "auto";
          }

          // system msg
          if (
            name === "system" ||
            (msg.author === "Ricepo" && msg.attributes.type !== "image")
          ) {
            type = "system";
          }

          // bottom margin add
          const bottom =
            list.length === index + 1 ? { marginBottom: "30px" } : null;

          return (
            <div key={msg.index} style={bottom}>
              {type !== "system" && type !== "auto" && (
                <div className={`item ${status}`}>
                  <img
                    className="avatar"
                    src={`${process.env.PUBLIC_URL}/${avatar}`}
                    alt=""
                  />
                  <div className="info">
                    {/* support extra info */}
                    {sourceType === "support" ? (
                      <span>
                        {time} 【{name}】
                      </span>
                    ) : null}
                    {msg.type === "media" || msg.attributes.type === "image" ? (
                      msg.content
                    ) : (
                      <p className={`msg ${mode}`}>{msg.body}</p>
                    )}
                  </div>
                </div>
              )}
              {type === "system" && (
                <div className="item center">
                  <p className="system">{msg.body}</p>
                </div>
              )}
              {type === "auto" && (
                <div className="item center o-5">
                  <span className="auto">{time}</span>
                </div>
              )}
            </div>
          );
        })}
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}
