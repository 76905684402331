import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";
import _ from "lodash";
import * as Req from "../services/request";

const goodReason = [
  "回复快速及时",
  "一次性解决问题",
  "态度热情礼貌",
  "会推荐给朋友",
];

const badReason = ["回复不及时", "没有解决问题", "态度差", "客服不专业"];

export default class Message extends React.Component {
  constructor(props) {
    super(props);

    // initial
    this.state = {
      satisfied: true,
      reason: "",
      selects: goodReason,
      detail: "",
    };
  }

  // submit rating
  submit = async () => {
    const { satisfied, reason: originalReason, detail } = this.state;
    const { params } = this.props;
    let { channel } = params;
    const taskSid = _.get(channel, "channelState.attributes.taskSid");

    // send rating info to backend
    try {
      await Req.survey({
        taskSid,
        reason: _.isEmpty(detail)
          ? originalReason
          : `【${detail}】${originalReason}`,
        /* rating satisfied is'1' dissatisfied is '2' */
        rating: satisfied ? 1 : 2,
      });
    } catch (err) {
      alert(err);
    }

    // close modal
    this.props.toggle();

    // thank you alert
    if (satisfied) {
      window.alert(
        "感谢您的评价，您对我们的认可温暖了RICEPO的全体员工。期待下次与您的美味邂逅！(We’re glad that you enjoyed the ordering experience, and we’re looking forward to your next order!)"
      );
    } else {
      window.alert(
        "我们已将具体问题提交给了客服经理并会与为您提供服务的客服代表沟通。谢谢您使用我们的平台，请再给我们一次机会为您提供更好的服务！(We have submitted your feedback to the customer service manager who will follow up with you. Thank you for using our platform. Please give us another chance to provide you with excellent customer service.)"
      );
    }
  };

  // select rating result
  selectSatisfy = (value) => {
    this.setState({
      satisfied: value,
      selects: value ? goodReason : badReason,
      detail: "",
    });
  };

  // select reason
  selectReason = (key) => {
    const { selects } = this.state;
    this.setState({
      detail: selects[key],
    });
  };

  // textarea input method
  input = (e) => {
    this.setState({ reason: e.target.value });
  };

  render() {
    const { modal, toggle } = this.props;
    const { satisfied, reason, selects, detail } = this.state;

    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader className="rate">
            <div className="center h4 top">客服意见</div>
            <div className="h5 title">您觉得我们的客服如何?</div>
          </ModalHeader>
          <ModalBody className="rate">
            {/* select icon */}
            <div>
              <img
                className="satisfy"
                onClick={() => {
                  this.selectSatisfy(false);
                }}
                src={!satisfied ? "/bad_active.png" : "/bad.png"}
                alt=""
              />
              <img
                className="satisfy"
                onClick={() => {
                  this.selectSatisfy(true);
                }}
                src={satisfied ? "/good_active.png" : "/good.png"}
                alt=""
              />
            </div>
            {/* select detail */}
            <div className="detail">
              {_.map(selects, (s, k) => {
                return (
                  <div
                    key={k}
                    className="selectItem"
                    onClick={() => {
                      this.selectReason(k);
                    }}
                  >
                    <div className="h6">{s}</div>
                    {detail === s ? (
                      <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/tick_dark.svg`}
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>

            {/* other advice */}
            <div className="detail">
              <div className="h5 title center">其他意见</div>
              <Input
                value={reason}
                onChange={this.input}
                type="textarea"
                rows={4}
              />
            </div>
          </ModalBody>
          <ModalFooter className="rate">
            <div className="detail">
              <Button color="primary" onClick={this.submit}>
                提交
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
