import React from "react";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
import * as Req from "../services/request";

export default class Question extends React.Component {
  // submit rating
  submit = async () => {
    const { params, mergeState } = this.props;
    let { sourceType, channelId, channelName } = params;

    mergeState({ confirmModal: false, loading: true });

    try {
      await Req.chat({ contact: channelName, type: sourceType, channelId });
    } catch (err) {
      alert(err);
    }

    mergeState({ loading: false });
  };

  toggle = () => {
    const { modal, mergeState } = this.props;
    mergeState({ confirmModal: !modal });
  };

  render() {
    const { modal } = this.props;

    return (
      <div>
        <Modal isOpen={modal} centered={true} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            当前客服会话已结束，是否重新联系客服?（Current support chat is
            closed, Do you want to contact support again?）
          </ModalHeader>
          <ModalFooter>
            <Button color="primary" onClick={this.submit}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
