const T = {
  food_not_ready: {
    name: "food not ready 饭店未出餐",
    content: [
      "司机已到达饭店，但由于饭店还未出餐，您的配送可能会有些延迟，请谅解",
    ],
    /* open: true, */
  },
  bad_traffic: {
    name: "bad traffic 交通拥堵延迟",
    content: ["由于交通拥堵，您的订单可能会有些延迟，请谅解"],
  },
  bad_weather: {
    name: "bad weather 天气因素延迟",
    content: ["由于天气影响，您的订单可能会有些延迟，请谅解"],
  },
  other_reason_delayed: {
    name: "other reason delayed 其他原因延迟",
    content: ["很抱歉，您的订单将会有些延迟，司机会尽快为您配送，请谅解"],
  },
  on_the_way: {
    name: "on the way 正在配送，很快到达",
    content: ["司机正在为您配送，很快将会到达"],
  },
  arrived: {
    name: "arrived 已到达，请取餐",
    content: ["司机已到达，请尽快取餐"],
  },
};

export default T;
