import React from "react";
import _ from "lodash";
import Message from "./component/message";
import Reply from "./component/reply";
import Question from "./component/question";
import Rating from "./component/rating";
import Confirm from "./component/confirm";
import { init, parseMsg } from "./services/method";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    // parse url
    const search = window.location.search.slice(1);
    const params = new URLSearchParams(search);

    // initial state
    const obj = {
      messages: [],
      textarea: "",
      loading: true,
      title: "Support",
      replyModal: false,
      questionModal: false,
      ratingModal: false,
      confirmModal: false,
      mode: "light",
    };
    for (let v of params.keys()) {
      obj[v] = params.get(v);
    }

    // URLSearchParams parse will replace + to " ", just like "+186222"->" 186222", when use name to sms customer will occur error
    if (obj.sourceType !== "restaurant") {
      obj.channelName = obj.channelName && obj.channelName.replace(/\s+/g, "+");
    }

    obj.channelId = obj.channelId && obj.channelId.replace(/\s+/g, "+");

    /* the channelId of flex chat is phone */
    if (/\+[0-9]*$/.test(obj.channelId) || obj.sourceType === "restaurant") {
      obj.flex = true;
    }

    this.state = obj;

    // add resize method , when call up keboard, message can scroll to bottom automaticlly
    window.addEventListener("resize", () => {
      this.setState({ refresh: "" });
    });
  }

  // tap Enter in keyboard will occur click
  keyDown = (e) => {
    const { textarea, channel } = this.state;

    if (e.keyCode === 13) {
      if (_.includes(textarea, "xxx")) {
        window.alert(`亲，还有xxxx还没有去掉哦！:${textarea}`);
        return;
      }

      if (!textarea.trim()) {
        this.setState({ textarea: "" });
        return;
      }

      // twilio api send msg
      channel.sendMessage(textarea);

      // input back to the empty status
      this.setState({ textarea: "" });
    }
  };

  // textarea input method
  input = (e) => {
    this.setState({ textarea: e.target.value });
  };

  // listen method
  listen = (channel, client) => {
    const { sourceType } = this.state;

    /* Listen for new messages sent to the channel */
    channel.on("messageAdded", async (m) => {
      const status = _.get(m, "channel.channelState.attributes.status");

      /* only check customer or dirver msg except suppor or system msg */
      if (status === "INACTIVE" && /\+[0-9]*$/.test(m.author)) {
        if (sourceType === "customer") {
          this.setState({ confirmModal: true });
        } else {
          this.setState({ questionModal: true });
        }
      }

      const { messages } = this.state;
      parseMsg(m);

      // reject for support
      if (_.includes(["ricepo-rating-reply", "customer-rating-fire"], m.body)) {
        return;
      }

      messages.push(m);
      this.setState({ messages });

      // support send alert used as badge
      const [type, name] = m.author.split(":");

      // support msg need to send alert for connect
      if (!name && !/\+[0-9]*$/.test(type)) {
        window.parent.postMessage("alert", "*");
      }
    });

    client.on("channelUpdated", ({ channel, updateReasons }) => {
      console.log("updateReasons:", updateReasons);

      if (_.includes(updateReasons, "attributes")) {
        const { status } = _.get(channel, "channelState.attributes", {});
        if (status === "INACTIVE") {
          if (sourceType === "customer") {
            this.setState({ ratingModal: true });
            return;
          }
          this.setState({ questionModal: true });
          return;
        }
        if (status === "ACTIVE") {
          this.setState({ questionModal: false, ratingModal: false });
          return;
        }
      }
    });
  };

  // upload image method
  image = (e) => {
    const { channel } = this.state;
    const f = e.target.files[0];

    // if image doesn't exist
    if (!f) {
      return;
    }

    const fr = new FileReader();
    fr.onload = (data) => {
      // send PNG image as Buffer with content type image/png
      channel.sendMessage({
        contentType: "image/png",
        media: data.target.result,
      });
    };
    fr.readAsArrayBuffer(f);
  };

  // setState merge method
  mergeState = (obj) => {
    this.setState(obj);
  };

  async componentDidMount() {
    const { token, channelId } = this.state;

    if (!token || !channelId) {
      alert("Please Contact Support");
      console.log("Param lost:", this.state);
      return;
    }

    const obj = await init(this.state);
    this.setState(obj);

    const channel = obj.channel;

    if (!channel) {
      return;
    }

    this.listen(channel, obj.client);
  }

  render() {
    let {
      textarea,
      messages,
      loading,
      title,
      sourceId,
      sourceType,
      replyModal,
      flex,
      questionModal,
      confirmModal,
      ratingModal,
      mode,
    } = this.state;

    return (
      <div className={`container ${mode}`}>
        {/* header */}
        {sourceType === "support" && (
          <div className="header">
            <img src={`/ricepo_${mode}.png`} alt="" />
            {title}
          </div>
        )}

        {/* message content */}
        <Message
          list={messages}
          sourceId={sourceId}
          sourceType={sourceType}
          mode={mode}
        />

        {/* footer */}
        <div className={`footer footer_${mode}`}>
          <input
            placeholder="type a message"
            disabled={loading}
            onChange={this.input}
            value={textarea}
            onKeyDown={this.keyDown}
          />
          <input
            type="file"
            onChange={this.image}
            accept="image/*"
            id="upload"
            style={{ display: "none" }}
          />
          <label htmlFor="upload">
            <img alt="图片" src={`/attachment_${mode}.svg`} />
          </label>
          {sourceType === "driver" && (
            <img
              alt="快速回复"
              src="/reply.png"
              style={{ width: "32px", marginLeft: "10px" }}
              onClick={() => {
                this.setState({ replyModal: !replyModal });
              }}
            />
          )}
        </div>

        {/* Rely Template Modal */}
        <Reply
          modal={replyModal}
          toggle={() => {
            this.setState({ replyModal: !replyModal });
          }}
          input={this.input}
        />

        {/* Question Modal */}
        {flex && (
          <Question
            modal={questionModal}
            params={this.state}
            listen={this.listen}
            mergeState={this.mergeState}
          />
        )}

        {/* Question Modal */}
        {flex && (
          <Confirm
            modal={confirmModal}
            params={this.state}
            mergeState={this.mergeState}
          />
        )}

        {/* Rating Modal */}
        {flex && (
          <Rating
            modal={ratingModal}
            params={this.state}
            toggle={() => {
              this.setState({ ratingModal: !ratingModal });
            }}
          />
        )}

        {/* Loading Spin */}
        {loading && (
          <div>
            <img
              alt=""
              className="loading"
              src="/loading_black.gif"
              style={{ width: "60px" }}
            />
          </div>
        )}
      </div>
    );
  }
}
