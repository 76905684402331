import React from "react";
import _ from "lodash";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import template from "../config/template";

export default class Message extends React.Component {
  constructor(props) {
    super(props);

    // inital the category menu, common sheet defaultly show
    const obj = {};
    _.forEach(template, (v, k) => {
      obj[k] = !!v.open;
    });

    this.state = obj;
  }

  // tap category can hide and show it
  tap = (key) => {
    const obj = {};
    obj[key] = !this.state[key];
    this.setState(obj);
  };

  // click line and add it to the input area and close the modal
  select = (txt) => {
    const obj = {};
    _.set(obj, "target.value", txt);
    this.props.input(obj);
    this.props.toggle();
  };

  render() {
    const { modal, toggle } = this.props;

    return (
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Reply Friendly</ModalHeader>
          <ModalBody>
            {Object.keys(template).map((k, i) => {
              return (
                <ListGroup key={i}>
                  <ListGroupItem
                    className="list-group-item-action list-group-item-secondary"
                    onClick={() => {
                      this.tap(k);
                    }}
                  >
                    {template[k].name}
                  </ListGroupItem>
                  <Collapse isOpen={this.state[k]}>
                    <ListGroupItem>
                      <ListGroup>
                        {template[k].content.map((v, k) => {
                          return (
                            <ListGroupItem
                              className="list-group-item-action"
                              onClick={() => {
                                this.select(v);
                              }}
                              key={k}
                            >
                              {v}
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    </ListGroupItem>
                  </Collapse>
                </ListGroup>
              );
            })}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
