import _ from "lodash";
import React from "react";
import { Client } from "twilio-chat";

// click image will open new window
export const enlarge = async (msg) => {
  const url = await msg.media.getContentTemporaryUrl();
  window.open(url);
};

const getHistory = async (channel, sourceType) => {
  const pageSize = sourceType === "support" ? 100 : 20;
  const res = await channel.getMessages(pageSize);

  let items = res.items;

  if (sourceType === "support" && res.hasPrevPage) {
    const preRes = await res.prevPage(); // here we can ask for following page

    items = _.concat(preRes.items, items);
  }

  // handle media msg
  for (let msg of items) {
    parseMsg(msg);
  }

  // filter hide item
  return _.reject(items, (i) =>
    _.includes(["ricepo-rating-reply", "customer-rating-fire"], i.body)
  );
};

export const init = async ({
  token,
  channelId,
  channelName,
  flex,
  sourceType,
}) => {
  let channel = null;
  let client = null;

  /* Initialize the Chat client */
  try {
    client = await Client.create(token);
  } catch (e) {
    alert(
      "Session expiration，Please open a new chat\n(会话过期，请重新打开聊天窗口)"
    );
    return {};
  }

  try {
    /* Get the general chat channel, which is where all the messages are sent in this simple application */
    channel = await client.getChannelByUniqueName(channelId);
  } catch (err) {
    /* If it doesn't exist, let's create it and use customerId as unique id */
    channel = flex
      ? null
      : await client.createChannel({
          uniqueName: channelId,
          friendlyName: channelName,
        });
  }

  /* stop if no channel */
  if (!channel) {
    return {
      channel: null,
      loading: false,
      messages: [],
      title: "",
      client,
      questionModal: true,
    };
  }

  // join channel
  try {
    await channel.join();
  } catch (err) {
    // do nothing just avoid exsist user join channel
  }

  // Get history message
  const history = await getHistory(channel, sourceType);

  // set default title name as customer phone or driver email
  document.title = "RICEPO客服";

  return {
    channel,
    loading: false,
    messages: history,
    title: channel.friendlyName,
    client,
    /* questionModal: _.get(channel, "attributes.status") !== "ACTIVE", */
  };
};

export const parseMsg = async (msg) => {
  const type = msg.attributes.type || msg.type;

  if (!_.includes(["media", "image"], type)) {
    return;
  }

  /* image condition */
  const url =
    type === "image"
      ? msg.attributes.url
      : await msg.media.getContentTemporaryUrl();

  msg.content = (
    <img
      alt="图片"
      className="thumbnail"
      onClick={() => {
        window.open(url);
      }}
      src={url}
    />
  );
};
